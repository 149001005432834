import {
  api,
  getUserDataFromCookie,
  getUserName,
} from '@iblai/ibl-web-react-common';
import { json } from 'react-router-dom';
import { mentorIsIframe } from '../shared';
import { redirectToAuth } from '../auth';

export const authGuard = (
  requireAdminRole,
  requireLogin,
  appData,
  setAppData
) => {
  const userDataFromCookie = getUserDataFromCookie();
  const anonymous =
    new URLSearchParams(window.location.search)?.get('mode') === 'anonymous';

  return async () => {
    if (anonymous || mentorIsIframe() || !requireLogin) {
      return [];
    }

    if (
      (requireLogin && (!appData?.axd_token || !appData?.dm_token)) ||
      !userDataFromCookie ||
      userDataFromCookie?.user_id !== appData.userData?.user_id
    ) {
      return redirectToAuth();
    }
    const getUserMetadataPromise = new Promise((_resolve, _reject) => {
      if (!Object.keys(appData.userMetaData).length) {
        api.ibledxusers.getUsersManageMetadata(
          { username: getUserName() },
          function (data) {
            _resolve({
              userMetaData: { ...data },
              userMetaDataLoaded: true,
            });
          },
          () => {
            _reject();
          }
        );
      } else {
        _resolve({});
      }
    });
    const getTenantsPromise = new Promise((_resolve, _reject) => {
      const selectedTenant = localStorage.getItem('tenant');
      if (!localStorage.getItem('tenants')) {
        api.ibledxtenants.getUserTenants((tenants) => {
          api.iblutils.saveUserTenantsDataToLocalStorage(
            tenants,
            selectedTenant
          );
          _resolve({
            current_tenant: localStorage.getItem('current_tenant'),
            tenants: localStorage.getItem('tenants'),
          });
        });
      } else {
        let currentTenant = localStorage.getItem('current_tenant');
        let currentTenantKey;
        try {
          currentTenantKey = JSON.parse(currentTenant)?.key;
        } catch (e) {}

        api.iblutils.saveUserTenantsDataToLocalStorage(
          JSON.parse(localStorage.getItem('tenants')),
          selectedTenant
        );

        // Getting the new tenant after setting

        currentTenant = JSON.parse(localStorage.getItem('current_tenant'));
        if (currentTenantKey !== currentTenant.key) {
          _resolve({
            current_tenant: localStorage.getItem('current_tenant'),
            tenants: localStorage.getItem('tenants'),
          });
        } else {
          _resolve({});
        }
      }
    });
    try {
      const [_userMetaDataInfo, _tenantInfo] = await Promise.all([
        getUserMetadataPromise,
        getTenantsPromise,
      ]);
      const currentTenantData = JSON.parse(_tenantInfo.current_tenant);

      if (requireAdminRole && currentTenantData.is_admin) {
        throw new Response('Not Admin', { status: 403 });
      }
      if (
        Object.keys(_userMetaDataInfo)?.length ||
        Object.keys(_tenantInfo)?.length
      ) {
        setAppData({ ...appData, ..._userMetaDataInfo, ..._tenantInfo });
      }
      return json({}, { status: 200 });
    } catch (error) {
      return json({}, { status: 500 });
    }
  };
};
