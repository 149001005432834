import React, { useContext, useEffect, useRef, useState } from 'react';
import Home from './home';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../lib/redux/auth/slice';
import useSetCurrentMentor from '../../hooks/api/mentors/useSetCurrentMentor';
import PageLoader from '../loader/page-loader';
import { Toaster } from 'react-hot-toast';
import useIframeEvents from '../../hooks/iframe/useIframeEvents';
import Creatementormodalbox from '../../components/Creatementormodalbox/Creatementormodalbox';
import Editmentormodalbox from '../../components/Editmentormodalbox/Editmentormodalbox';
import useAnonymousMode from '../../hooks/iframe/useAnonymousMode';
import useAnonymousMentor from '../../hooks/iframe/useAnonymousMentor';
import useGuidedSuggestedPrompts from '../../hooks/chat-room/useGuidedSuggestedPrompts';
import {
  AppDataContext,
  useDashboard,
  useUserTimeTracking,
} from '@iblai/ibl-web-react-common';
import useUserAuth from '../../hooks/user/useUserAuth';

export default function HomeWrapper() {
  const anonymous = useAnonymousMode();
  const auth = useSelector((state) => state.auth.data);
  const sessionId = useSelector((state) => state.chat.sessionId);
  useUserTimeTracking();

  const mentors = useSelector((state) => state.mentors);
  const [startMentorSetup, setStartMentorSetup] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSetCurrentMentor(startMentorSetup);
  const anonymousMentor = useAnonymousMentor();
  const { handleIframeMessage, notifyParentOnReady } = useIframeEvents();
  const { modals } = useSelector((state) => state.navigation.data);
  const { loadGuidedPrompts } = useGuidedSuggestedPrompts();
  const { setupUserAuth } = useUserAuth();

  const pathName = window.location.pathname;

  useEffect(() => {
    if (!anonymous && auth.user && auth.tenant) {
      setStartMentorSetup(true);
    }
  }, [auth]);

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);
    notifyParentOnReady();
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  useEffect(() => {
    if (sessionId) {
      loadGuidedPrompts();
    }
  }, [sessionId]);

  useEffect(() => {
    setupUserAuth();
  }, []);

  return (
    <>
      {(pathName.includes('/share/chat') ||
        ((auth?.user || anonymous) && mentors?.mentor)) && <Home />}{' '}
      {(loading || anonymousMentor?.loading) && <PageLoader />}
      {modals?.['mentor-creation-modal'] && !mentors?.mentor && (
        <Creatementormodalbox />
      )}
      {modals?.['edit-mentor-modal'] && mentors?.mentors?.count === 0 && (
        <Editmentormodalbox />
      )}
      <Toaster
        toastOptions={{
          className: 'site-toaster-container',
        }}
        containerStyle={{
          zIndex: 2147483647,
        }}
      />
    </>
  );
}
