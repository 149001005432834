import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PromptItem from '../../prompts/prompt-item';
import { useTriggers } from '../../../hooks/navigation';
import {
  getPromptFromString,
  handleLayoutsWithCustomTooltipsJS,
  userIsOnTrial,
} from '../../../utils/shared';
import useMentorPrompts from '../../../hooks/api/mentors/useMentorPrompts';
import { useDebounce } from '../../../hooks/useDebounce';

export default function MentorPrompts({
  creationMode = false,
  mentor = {},
  setMentor = () => {},
}) {
  const mentors = useSelector((state) => state.mentors);
  const { handleAddNewPromptBtnClick, handleOpenFreeTrialModal } =
    useTriggers();
  const { suggestedPrompts } = useMentorPrompts();

  useDebounce(handleLayoutsWithCustomTooltipsJS, 200, []);

  return (
    <div className="w-layout-vflex edit-mentor-system-prompt-tab">
      <div className="w-layout-hflex moderaion-proactive-prompt-block">
        <div className="w-layout-vflex prompt-category-container">
          <div
            data-w-id="8c90a795-9942-b013-5ad8-3276936686d6"
            className="w-layout-hflex prompt-category-container-title-block has-custom-tooltip"
          >
            <h5 className="prompt-category-container-title">System Prompt</h5>
            <img
              src="/images/info_1.svg"
              loading="lazy"
              alt=""
              className="prompt-category-container-title-icon"
            />
            <div className="w-layout-vflex tooltip prompt-tooltip system-prompt-tooltip">
              <div className="w-layout-hflex prompt-tooltip-header-block">
                <div className="w-layout-hflex prompt-tooltip-header-icon-block">
                  <img
                    src="/images/terminal.svg"
                    loading="lazy"
                    alt=""
                    className="image-62 no-filter-applied-in-darkmode"
                  />
                </div>
                <h5 className="prompt-tooltip-title">System Prompt</h5>
              </div>
              <div className="w-layout-hflex tooltip-bg-img-block"></div>
              <div className="w-layout-vflex prompt-tooltip-details-block">
                <p className="prompt-tooltip-details-parag">
                  It sets the behavior, tone, and guidelines for how it should
                  respond to user inputs.
                  <br />‍<br /> It establishes the context and defines the
                  boundaries within which the AI operates. <br />‍<br />
                  This prompt helps guide the AI&#x27;s responses to align with
                  specific goals, such as maintaining a formal tone or focusing
                  on a particular subject. <br />‍<br />
                  Essentially, it influences how the AI interprets and generates
                  text.
                </p>
              </div>
              <div className="prompt-tooltip-left-triangle-pointer"></div>
            </div>
          </div>
          <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid _1column-grid">
            <PromptItem
              prompt={{
                ...getPromptFromString(
                  creationMode
                    ? mentor?.system_prompt
                    : mentors?.edit?.mentor?.system_prompt
                ),
                isSystem: true,
                type: 'system_prompt',
                creationMode,
                mentor,
                setMentor,
              }}
            />
          </div>
        </div>

        {(creationMode || mentors?.edit?.mentor?.proactive_prompt) && (
          <div className="w-layout-vflex prompt-category-container">
            <div
              data-w-id="9248d74a-197a-5a87-38e7-a4b3c898b3e0"
              className="w-layout-hflex prompt-category-container-title-block has-custom-tooltip"
            >
              <h5 className="prompt-category-container-title">
                Proactive Prompt
              </h5>
              <img
                src="/images/info_1.svg"
                loading="lazy"
                alt=""
                className="prompt-category-container-title-icon"
              />
              <div className="w-layout-vflex tooltip prompt-tooltip proactive-prompt">
                <div className="w-layout-hflex prompt-tooltip-header-block">
                  <div className="w-layout-hflex prompt-tooltip-header-icon-block">
                    <img
                      src="/images/waypoints.svg"
                      loading="lazy"
                      alt=""
                      className="image-62 no-filter-applied-in-darkmode"
                    />
                  </div>
                  <h5 className="prompt-tooltip-title">Proactive Prompt</h5>
                </div>
                <div className="w-layout-hflex tooltip-bg-img-block proactive"></div>
                <div className="w-layout-vflex prompt-tooltip-details-block">
                  <p className="prompt-tooltip-details-parag">
                    It&#x27;s a hidden directive provided to the AI model that
                    initiates the conversation, allowing the application to
                    begin with a response instead of a blank chat. <br />‍
                    <br />
                    It is designed to guide the AI to generate an opening
                    message, setting the tone and context for the interaction
                    without requiring input from the user first. <br />‍<br />
                    This helps create a more engaging and dynamic experience by
                    having the AI take the lead in starting the conversation.
                  </p>
                </div>
                <div className="prompt-tooltip-left-triangle-pointer proactive"></div>
              </div>
            </div>
            <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid _1column-grid">
              <PromptItem
                prompt={{
                  ...getPromptFromString(
                    creationMode
                      ? mentor?.proactive_prompt
                      : mentors?.edit?.mentor?.proactive_prompt
                  ),
                  isSystem: true,
                  type: 'proactive_prompt',
                  creationMode,
                  mentor,
                  setMentor,
                }}
              />
            </div>
          </div>
        )}
      </div>

      {!creationMode && (
        <div className="w-layout-vflex prompt-category-container">
          <div
            data-w-id="e097b85c-9716-dbc5-99c1-9b2dbccdeb6a"
            className="w-layout-hflex prompt-category-container-title-block  has-custom-tooltip"
          >
            <h5 className="prompt-category-container-title">
              Suggested Prompt
            </h5>
            <img
              src="/images/info_1.svg"
              loading="lazy"
              alt=""
              className="prompt-category-container-title-icon"
            />
            <div className="w-layout-vflex tooltip prompt-tooltip suggested-prompt">
              <div className="w-layout-hflex prompt-tooltip-header-block">
                <div className="w-layout-hflex prompt-tooltip-header-icon-block">
                  <img
                    src="/images/layout-list-1.svg"
                    loading="lazy"
                    alt=""
                    className="image-62 no-filter-applied-in-darkmode"
                  />
                </div>
                <h5 className="prompt-tooltip-title">Suggested Prompt</h5>
              </div>
              <div className="w-layout-hflex tooltip-bg-img-block suggested"></div>
              <div className="w-layout-vflex prompt-tooltip-details-block">
                <p className="prompt-tooltip-details-parag">
                  It&#x27;s a recommendation provided to the user, guiding them
                  on how to interact with the AI model based on its
                  capabilities.
                  <br /> <br />
                  These prompts are crafted to encourage meaningful and
                  effective interactions by highlighting questions or tasks the
                  AI is well-suited to handle. <br />‍<br />
                  By offering tailored suggestions, the AI helps users make the
                  most of the system&#x27;s strengths and ensures a smoother,
                  more productive experience.
                </p>
              </div>
              <div className="prompt-tooltip-left-triangle-pointer suggested"></div>
            </div>
          </div>
          <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid">
            {suggestedPrompts?.map((item, i) => (
              <PromptItem
                prompt={{
                  ...getPromptFromString(item?.prompt),
                  index: item.id,
                  id: item.id,
                }}
                key={`${i}-prompt`}
              />
            ))}
            <div
              className="w-layout-vflex prompt-grid-element add-prompt-grid-element light-grey-bg-dark-mode greyish-bg-in-darkmode add-prompt-grid-element-1"
              data-w-id="1dc72672-b432-9a64-835d-c12c25541aac"
              onClick={
                userIsOnTrial()
                  ? handleOpenFreeTrialModal
                  : handleAddNewPromptBtnClick
              }
              id="w-node-_1dc72672-b432-9a64-835d-c12c25541aac-e5765214"
            >
              <div className="w-layout-hflex flex-block-18">
                <img
                  alt=""
                  className="image-28"
                  loading="lazy"
                  src="/images/plus-4.svg"
                />
              </div>
              <h5 className="add-new-prompt-label">Add New Prompt</h5>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
