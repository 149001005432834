import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDataTable, exportTableToExcel } from '../../../utils/shared';
import { chatActions } from '../../../lib/redux/chat/slice';
import { marked } from 'marked';
import { BASE_API_URL } from '../../../utils/api';
import { navigationActions } from '../../../lib/redux/navigation/slice';
import useApi from '../base/useApi';
import { toast } from 'react-hot-toast';
import useMentorUtils from '../../utils/useMentorUtils';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import { useNavigate } from 'react-router-dom';

export default function useSessionMessageItem(item, type) {
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuTrigger, setShowMenuTrigger] = useState(false);
  const messageMenuRef = useRef(null);
  const auth = useSelector((state) => state.auth.data);
  const chat = useSelector((state) => state.chat);
  const nav = useSelector((state) => state.navigation.data);
  const [deleting, setDeleting] = useState(false);
  const api = useApi();
  const { getMentor } = useMentorUtils();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const itemMessages = [...(item?.messages ?? [])].reverse();

  const message = itemMessages?.[itemMessages?.length - 1];

  const handleDeleteSession = async () => {
    setDeleting(true);
    const url = `/api/ai-mentor/orgs/${auth?.tenant.key}/users/${auth?.user?.user_nicename}/sessions/${item.session_id}/`;
    const response = await api.remove(url);
    if (!response?.error) {
      if (type !== 'pinned') {
        const newRecentMessages = chat?.recentMessages?.results?.filter(
          (session) => session.session_id !== item.session_id
        );
        dispatch(
          chatActions.recentMessagesUpdated({
            ...chat?.recentMessages,
            results: newRecentMessages,
          })
        );
      } else {
        const newPinMessages = chat?.pinMessages?.results.filter(
          (session) => session.session_id !== item.session_id
        );
        dispatch(
          chatActions.pinMessagesUpdated({
            ...chat?.pinMessages,
            results: newPinMessages,
          })
        );
      }
      toast.success('Chat deleted', {
        autoClose: 3000,
      });
      setDeleting(false);
      setShowMenu(false);
    }
  };

  const handlePinMessage = async () => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant.key}/users/${auth?.user?.user_nicename}/pin-message/`;
    const payload = {
      username: auth?.user?.user_nicename,
      session_id: item.session_id,
    };
    const response = await api.post(url, payload);
    if (!response?.error) {
      setShowMenu(false);
      const newRecentMessages = chat?.recentMessages?.results?.filter(
        (session) => session.session_id !== item.session_id
      );
      dispatch(
        chatActions.recentMessagesUpdated({
          ...chat?.recentMessages,
          results: newRecentMessages,
        })
      );

      dispatch(
        chatActions.pinMessagesUpdated({
          ...chat.pinMessages,
          results: [...chat.pinMessages.results, item],
        })
      );
    }
  };

  const handleMessageExport = () => {
    let messages = item.messages.filter((item) => item?.message?.data?.content);
    if (type === 'pinned') {
      messages = [...messages].reverse();
    }
    createDataTable(messages);
    exportTableToExcel('export-message-table');
    setShowMenu(false);
  };

  const handleChatBoxMenuClick = () => {
    setShowMenu(true);
  };

  function handleClickOutsideMenu(event) {
    if (
      showMenu &&
      messageMenuRef.current &&
      !messageMenuRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
  }

  const handleSessionSelected = async () => {
    let messages = item.messages;
    messages = [...messages].reverse();

    const msgToDisplay = messages?.map((msg) => {
      return {
        type: msg?.message?.type,
        content: msg?.message?.data?.content
          ? marked.parse(msg?.message?.data?.content)
          : '',
        rawContent: msg?.message?.data?.content,
        mentor: item?.mentor,
        sources: item.document_sources,
        isChatHistory: true,
      };
    });
    const sessionMentor = await getMentor(item.mentor?.name);
    if (sessionMentor) {
      dispatch(mentorActions.currentMentorUpdated(sessionMentor));
      navigate(`/platform/${auth.tenant.key}/${sessionMentor.slug}`);
    }

    dispatch(chatActions.sessionUpdated(item.session_id));
    dispatch(chatActions.messagesUpdated(msgToDisplay));

    dispatch(navigationActions.navigationUpdated({ ...nav, sidebar: true }));
  };

  const getSessionTitle = (size) => {
    let title = message?.message?.data?.content ?? 'No message here';
    if (title.split(' ').length > size) {
      title = title.split(' ').slice(0, size).join(' ') + '...';
    }
    return title;
  };

  // TODO: Integrate unpin session
  const handleUnpinMessage = async () => {};

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [handleClickOutsideMenu]);

  return {
    message,
    getSessionTitle,
    handleSessionSelected,
    showMenu,
    showMenuTrigger,
    setShowMenuTrigger,
    setShowMenu,
    handleChatBoxMenuClick,
    messageMenuRef,
    handlePinMessage,
    handleUnpinMessage,
    handleMessageExport,
    handleDeleteSession,
    deleting,
  };
}
