import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from '../../../hooks/useDebounce';
import SubmitBtn from '../../../components/SubmitBtn/SubmitBtn';
import { isJSON } from '@iblai/ibl-web-react-common';
import { navigationActions } from '../../../lib/redux/navigation/slice';
export default function Flow() {
  const LANGFLOW_BASE_URL = process.env.REACT_APP_IBL_LANGFLOW_APP_BASE_URL;
  const mentor = useSelector((state) => state.mentors.edit.mentor);
  const langflowAppIframe = useRef(null);
  const [submitting, setSubmitting] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useDebounce(
    () => {
      if (iframeLoaded) {
        handleLangflowPostMessageAPI();
      }
    },
    200,
    [iframeLoaded]
  );

  useEffect(() => {
    window.addEventListener('message', handleSavingStatusEvent);
    return () => {
      window.removeEventListener('message', handleSavingStatusEvent);
    };
  }, []);

  const handleSavingStatusEvent = (event) => {
    if (event && event?.data?.langflowSaved) {
      setSubmitting(false);
    }
  };

  const handleLangflowPostMessageAPI = () => {
    //const iframedSite = document.querySelector('#embed-mentor-langflow-app');
    const message = {
      axd_token: localStorage.getItem('axd_token'),
      tenant: localStorage.getItem('tenant'),
    };
    langflowAppIframe.current.contentWindow.postMessage(message, '*');
  };

  const handleLangflowSave = () => {
    setSubmitting(true);
    const message = {
      triggerLangflowSave: true,
    };
    langflowAppIframe.current.contentWindow.postMessage(message, '*');
  };

  return (
    <>
      <iframe
        title={'Langlow APP'}
        id="embed-mentor-langflow-app"
        frameBorder={0}
        ref={langflowAppIframe}
        src={`${LANGFLOW_BASE_URL}/flow/${mentor?.unique_id}`}
        onLoad={() => setIframeLoaded(true)}
        //src={`${LANGFLOW_BASE_URL}/login`}
      ></iframe>
      <div className="langflow-app-save-btn-container">
        <SubmitBtn
          saving={submitting}
          onClick={submitting ? () => {} : () => handleLangflowSave()}
          label={'Save'}
        />
      </div>
    </>
  );
}
