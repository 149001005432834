import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { useEffect, useCallback } from 'react';
import { api, isJSON } from '@iblai/ibl-web-react-common';
import { navigationActions } from '../../lib/redux/navigation/slice';
import { chatActions } from '../../lib/redux/chat/slice';

export default function useIframeEvents() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('message', handleEnableCloserBtnEvent);
    return () => {
      window.removeEventListener('message', handleEnableCloserBtnEvent);
    };
  }, []);

  const handleEnableCloserBtnEvent = (event) => {
    if (!!event && !!isJSON(event?.data)) {
      const msg = JSON.parse(event.data);
      dispatch(
        navigationActions.iframeCloseButtonEnabled(!!msg?.enableCloseButton)
      );
    }
  };

  const handleThemePostMessage = (theme) => {
    const bodyEl = document.body;
    if (theme === 'dark') {
      bodyEl.classList.add('dark-mode');
    } else {
      bodyEl.classList.remove('dark-mode');
    }
  };

  const handleFocusPrompt = () => {
    document.getElementById('user-prompt')?.focus();
  };

  const handleCssPostMessage = (css) => {
    let style = document.createElement('style');
    style.innerText = css;
    document.head.appendChild(style);
  };

  const handleDefaultPromptChange = (data) => {
    let previewMentor = localStorage.getItem('previewMentorData');
    if (previewMentor) {
      previewMentor = JSON.parse(previewMentor);
      dispatch(
        mentorActions.currentMentorUpdated({
          ...previewMentor,
          settings: {
            ...previewMentor?.settings,
            suggested_message: data?.defaultPrompt,
            initial_message: data?.welcomeMessage,
          },
        })
      );
    }
  };

  const handleTokenMessage = (tokenData) => {
    Object.entries(tokenData).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });
    if (
      !localStorage.getItem('current_tenant') ||
      !localStorage.getItem('tenants')
    ) {
      api.ibledxtenants.getUserTenants((tenants) => {
        console.log('#################### Tenants are ', tenants);
        const selectedTenant = localStorage.getItem('tenant');
        api.iblutils.saveUserTenantsDataToLocalStorage(tenants, selectedTenant);
        console.log('#################### Reloading');
        window.location.reload();
      });
    } else {
      console.log('################## In Else');
      window.location.reload();
    }
  };

  const notifyParentOnTokenExpiry = () => {
    window.parent.postMessage(
      JSON.stringify({ authExpired: true, auth: { ...localStorage } }),
      '*'
    );
  };

  const notifyParentOnLoad = () => {
    window.parent.postMessage(
      JSON.stringify({ loaded: true, auth: { ...localStorage } }),
      '*'
    );
  };

  const notifyParentOnReady = () => {
    notifyParentOnLoad();
    if (!localStorage.getItem('axd_token')) {
      window.parent.postMessage(JSON.stringify({ ready: true }), '*');
    }
  };

  const handleIframeMessage = (event) => {
    const dataStr = event?.data;
    if (dataStr?.axd_token) {
      handleTokenMessage(dataStr);
    }

    if (typeof dataStr === 'string') {
      let data = null;
      try {
        data = JSON.parse(dataStr);
      } catch (e) {
        console.log(e);
      }

      if (data?.axd_token) {
        console.log('######## Mentor is communicating ##########');
        handleTokenMessage(data);
      }

      if (data?.css) {
        handleCssPostMessage(data?.css);
      }
      if (data?.theme) {
        handleThemePostMessage(data?.theme);
      }

      if (data?.focusPrompt?.toString() === '1') {
        handleFocusPrompt();
      }

      if (data?.internal_preview) {
        handleDefaultPromptChange(data);
      }

      if (data?.metadata) {
        dispatch(
          chatActions.showDisclaimerUpdated(data?.metadata?.safety_disclaimer)
        );
      }
    }
    if (dataStr?.reason === 'CONTEXT') {
      dispatch(chatActions.pageContentUpdate(dataStr.pageContent));
    }
  };

  return {
    handleIframeMessage,
    notifyParentOnReady,
    notifyParentOnTokenExpiry,
  };
}
